
import { computed, defineComponent } from 'vue';
import { useI18n } from '@/plugins/vue-i18n';
import LearningCatalogueAtoms from '@/components/catalogue/LearningCatalogueAtoms.vue';
import { AtomType } from '@/domains/atom';
import useCatalogueAssignment from '@/components/catalogue/composables/useCatalogueAssignment';
import CatalogueExtendedAtomCard from '@/components/catalogue/CatalogueAtomCard/CatalogueExtendedAtomCard.vue';
import useCatalogueAtomCard from '@/components/catalogue/composables/useCatalogueAtomCard';

export default defineComponent({
  name: 'LearningCatalogueCourses',
  components: {
    CatalogueExtendedAtomCard,
    LearningCatalogueAtoms,
  },
  setup() {
    const { t } = useI18n();
    const { onAssignAtom } = useCatalogueAssignment({ isRecommendations: false });
    const { getAtomPreviewUrl } = useCatalogueAtomCard();
    const title = t('LearningCatalogue.breadcrumbs.courses');
    const atomTypes = computed(() => [AtomType.TRACK, AtomType.SCORM]);

    return {
      title,
      atomTypes,
      onAssignAtom,
      getAtomPreviewUrl,
    };
  },
});
