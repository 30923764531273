import { render, staticRenderFns } from "./LearningCatalogueCourses.vue?vue&type=template&id=36d9069d&scoped=true&"
import script from "./LearningCatalogueCourses.vue?vue&type=script&lang=ts&"
export * from "./LearningCatalogueCourses.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36d9069d",
  null
  
)

export default component.exports